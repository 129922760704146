import React, { useContext, useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { ReservationContext } from '../../context/reservation.context';
import styles from './get-stpp-super-market.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop } from '@fortawesome/free-solid-svg-icons';

export const GetStopSuperMarket = ({setTotalPayment, isServiceActive, setIsServiceActive, setIsStopatStore, totalPayment, args}) => {
  const { reservation } = useContext(ReservationContext);
  const { rate_stop_super_store } = reservation;
  /* const [isServiceActive, setIsServiceActive] = useState(false); */
  //tooltip
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleButtonClick = () => {
    if (!isServiceActive) {
      setTotalPayment(parseFloat(totalPayment) + rate_stop_super_store); // Sumar el costo si se activa el servicio
      setIsStopatStore(true);
      setIsServiceActive(true); // Cambiar el estado del servicio a activo
    } else {
      setTotalPayment(parseFloat(totalPayment) - rate_stop_super_store); // Restar el costo si se desactiva el servicio
      setIsStopatStore(false);
      setIsServiceActive(false); // Cambiar el estado del servicio a inactivo
    }
  };
  return (
      <div>
        <h3>Extra Services:</h3>
        <div className={styles.container}>
          <div className={styles.icon_container}>
            <FontAwesomeIcon icon={faShop} />
          </div>
          <h6>Stop at the Supermarket or Grocery Store</h6>
          <h5 style={{ color: '#000000' }}>${rate_stop_super_store} USD</h5>
          <Button id={!totalPayment && 'disableButton'} color='primary' onClick={handleButtonClick} disabled={!totalPayment}>
            {isServiceActive ? 'Remove' : 'Add'}
          </Button>
          {!totalPayment && (
            <Tooltip
              {...args}
              isOpen={tooltipOpen}
              target="disableButton"
              toggle={toggle}
            >
              Please select Pickup and Destination first.
            </Tooltip>
          )}
        </div>
      </div>
  )
}
GetStopSuperMarket.args = {
  autohide: true,
  flip: true,
};

GetStopSuperMarket.argTypes = {
  placement: {
    control: { type: 'select' },
    options: ['top', 'left', 'right', 'bottom'],
  },
};