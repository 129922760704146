import axios from 'axios';
import configs from './../config/config';

export const sendMail = (data) => {
    return axios.post(`${configs.URL_API_BASE}/send-email`, data);
}

export const parserEmailData = (data) => {
    
    let obj = {
        "email": data.email,
        "reservation": {
            "folio": data.folio,
            "payment_id": data.payment_id,
            "search_params" : {
                "pickup_location": data.pickup_location,
                "destination": data.destination_location,
                "n_passengers": data.total_passengers,
                "trip_type": data.trip_type,
                "price": data.total_payment,
                "transportation": data.unit,
                "comments": data.observations
            },           
            "customer": {
                "fullname": data.fullname,
                "email": data.email,
                "phone": data.cellphone
            }
        }
    }

    if (data.destination_location !== 'Airport SJD') {
        obj['reservation']['arrival'] = {
            "date_time": data.arrival_datetime,
            "airline": data.arrival_airline,
            "fligth_number": data.arrival_flight_number
        }
    } else {
        obj['reservation']['arrival'] = null;
    }

    if (data.trip_type === 'Round Trip' || data.destination_location === 'Airport SJD') {
        obj['reservation']['departure'] = {
            "date_time": data.departure_datetime,
            "airline": data.departure_airline,
            "fligth_number": data.departure_flight_number,
            "hotel_departure_time": data.hotel_departure_time,
        };
    } else {
        obj['reservation']['departure'] = null;
    }
    if (data.extra_service !== null) {
        obj['reservation']['extra_service'] = data.extra_service
    }else {
        obj['reservation']['extra_service'] = null;
    }
    console.log('data:',data);
    console.log('obj:',obj);

    return obj;
}