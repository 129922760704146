import React, { Fragment, useContext, useEffect, useState  } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import 'moment/locale/cs.js';
import 'moment/locale/es.js';
import 'moment/locale/fr.js';
import 'moment/locale/nl.js';
import moment from 'moment';
import { ReservationContext } from '../../context/reservation.context';
import { parseToPromotionRate } from '../utils/utils';

export default function Sidebar({ data, unit, lang, totalPayment, tripValidator, isRoundTrip, isOneWayAirportDestination, isServiceActive }) {

    const { reservation } = useContext(ReservationContext);
    const {has_promotion, promotion, promotion_type, rate_stop_super_store } = reservation; // from context
    // const [promotionRate, setpromotionRate] = useState('')

    if(lang === 'eng'){
        moment.locale('en');
    }else{
        moment.locale('es');
    }

    const { 
        trip_type,
        total_passengers,
        destination_location,
        pickup_date,
        pickup_time,
        pickup_location,
        arrival_airline,
        arrival_flight_number,
        departure_flight_time,
        departure_date,
        departure_airline,
        departure_flight_number,
        departure_pickup_time_hotel,
        fullname,
        cellphone,
        email
    } = data;

    const pickup_datetime = pickup_date +' - '+ pickup_time

    const { image, label } = unit

    if(lang === 'eng'){
        moment.locale('en');
    } else{
        moment.locale('es');
    }
    // useEffect(() => {
    //   if(reservation.has_promotion) {
    //     let price_promotion = parseToPromotionRate(totalPayment)
    //     // console.log(price_promotion)
    //     setForm({
    //         ...data,
    //         rate_promotion: price_promotion,
    //         rate_regular: totalPayment
    //     })
    // } else {
    //       setForm({
    //           ...data,
    //           rate_promotion: '',
    //           rate_regular: totalPayment
    //       })
    //   }
    // }, [reservation, totalPayment])

    const shouldDisplayPickupNote = isRoundTrip || isOneWayAirportDestination;
    const hasPickupTime = departure_pickup_time_hotel;

    ///
    const handleRateWithStopAtSuper = (rateStopSuper, rate) => {
        console.log(rateStopSuper, rate)
        let result = rateStopSuper + parseFloat(rate,10);
        return `$${result.toFixed(1)} USD`;
    }
    const hasPromotion = has_promotion && promotion;
    const promotionAmount = `${promotion} ${promotion_type === 'percent' ? "%" : "usd"}`;
    // const regularPrice = handlePriceWithPromotion(rate_stop_super_store,data.rate_regular);
    // const promotionPrice = `$${rate_stop_super_store ? parseInt(rate_stop_super_store, 10) +  parseInt(data.rate_promotion, 10) : data.rate_promotion } USD`;

    // useEffect(() => {
    //     if(has_promotion && promotion) {
    //         setpromotionRate( `${promotion} ${promotion_type === 'percent' ? "%" : "usd"}`)
    //         if(iStopAtSuper){
    //             handleRateWithStopAtSuper(rate_stop_super_store, data?.rate_promotion)
    //         }
    //     }
    //     else {
    //         if(iStopAtSuper){
    //             handleRateWithStopAtSuper(rate_stop_super_store, data?.rate_promotion)
    //         } else {
    //         }
    //     }
    // }, [])
    
    return (
        <Fragment>
            <div className="summary-content">
                <h3>{lang === 'eng' ? 'Your Reservation Summary:' : 'Tu reservación:'}</h3>
                    {hasPromotion ? (
                        <Alert color="warning">
                            <h4>Promotion: <span>{promotionAmount} off</span></h4>
                            <hr style={{ borderColor: 'lightgray' }} />
                            <h5 style={{ textDecoration: 'line-through' }}>Regular Price: <span>{data.rate_regular}</span></h5>
                            <h5 style={{ fontSize: '32px' }}>TOTAL: <span>{totalPayment}</span></h5>
                        </Alert>
                    ) : (
                        <>
                            {
                                isServiceActive ? <>
                                    <h6>Stop at Super Market: <span>${rate_stop_super_store}</span></h6>
                                    <h6>Transportation Rate: <span>${totalPayment - rate_stop_super_store}</span></h6>
                                    <h5 style={{ fontSize: '32px' }}>TOTAL: <span>{totalPayment}</span></h5>
                                    </>
                                :
                                <h5 style={{ fontSize: '32px' }}>TOTAL: <span>{totalPayment}</span></h5>
                            }
                        </>
                    )}
                { 
                    image  && image !== 'No image' ? <>
                        <h4>{lang === 'eng' ? 'Transport Selected:' : 'Transporte Seleccionado'}</h4>
                        <img className="img-fluid" src={image} alt={'unidad Transroute'} />
                    </>
                    :
                    null
                }
                <p>{lang === 'eng' ? 'Service:' : 'Servicio:'}<br /> <span>{trip_type}</span></p>

                <Row className="px-0">
                    <Col xs={6}>
                        <p>{lang === 'eng' ? 'Transport:' : 'Transporte:'}<br /> <span>{label}</span></p>
                    </Col>
                    <Col xs={6}>
                        <p>{lang === 'eng' ? 'Passenger:' : 'Pasajeros:'}<br /> <span>{total_passengers}</span></p>
                    </Col>
                </Row>
                {
                    pickup_location &&
                        <p>{lang === 'eng' ? 'Pickup Location:' : 'Ubicación de recogida:'}<br /> <span>{pickup_location}</span></p>
                }
                {
                    destination_location &&
                        <p>{lang === 'eng' ? 'Destination Location:' : 'Ubicación de destino:'}<br /> <span>{destination_location}</span></p>
                }
                {
                    pickup_date &&
                        <h4>{lang === 'eng' ? tripValidator ? 'Departure Information' : 'Origin Pickup Information:' : tripValidator ? 'Información de partida' : 'Información de llegada'}</h4>
                }
                {
                    pickup_date &&
                        <p>{lang === 'eng' ? 'Pickup Date/Time:' : 'Fecha/Hora de recogida'} <br /> <span>{pickup_datetime}</span></p>
                }
                <Row className="px-0">
                    {
                        arrival_airline && (
                            <Col xs={6}>
                                <p>{lang === 'eng' ? "Airline's name:" : 'Nombre de la aerolínea:'}<br /> <span>{arrival_airline}</span></p>
                            </Col>
                        )
                    }
                    {
                        arrival_flight_number && (
                            <Col xs={6}>
                                <p>{lang === 'eng' ? "Flight's number:" : 'Número de vuelo:'}<br /> <span>{arrival_flight_number}</span></p>
                            </Col>
                        )
                    }
                </Row>
                {
                    trip_type === 'Round Trip' &&
                        <Fragment>
                            {
                                departure_date && <>
                                    <h4>{lang === 'eng' ? 'Departure Information:' : 'Información de partida:'}</h4>
                                    <p>{lang === 'eng' ? 'Pickup Date:' : 'Fecha Salida:'}<br /> <span>{departure_date} {departure_flight_time}</span></p>
                                </>

                            }              
                            <Row className="px-0">
                                {
                                    departure_airline && (
                                        <Col cs={6}>
                                            <p>{lang === 'eng' ? "Airline's name:" : 'Nombre de la aerolínea:'}<br /> <span>{departure_airline}</span></p>
                                        </Col>
                                    )
                                }
                                {
                                    departure_flight_number && (
                                        <Col xs={6}>
                                            <p>{lang === 'eng' ? "Flight's number:" : 'Número de vuelo:'}<br /> <span>{departure_flight_number}</span></p>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Fragment>
                }
                {
                    fullname && (
                        <Fragment>
                            <h4>{lang === 'eng' ? 'Contact / Traveler Information' : 'Información de Contacto / Viajero :'}</h4>
                            {
                                fullname && (
                                    <p>{lang === 'eng' ? 'Full name:' : 'Nombre completo:'} <br /> <span>{fullname}</span></p>
                                )
                            }
                            {
                                cellphone && (
                                    <p>{lang === 'eng' ? 'Phone / Cell number:' : 'Teléfono:'} <br /> <span>{cellphone}</span></p>
                                )
                            }
                            {
                                email && (
                                    <p>Email:<br /> <span>{email}</span></p>
                                )
                            }
                        </Fragment>
                    )
                }
                {
                   shouldDisplayPickupNote && hasPickupTime && (
                        <div style={{border: '1px solid #b00505', borderRadius: '8px', padding: '1rem'}}>
                            <p style={{textAlign: 'center', marginBottom:0}}>{lang === 'eng' ? 'Pickup at Hotel:' : 'Hora de recogida en el Hotel'} <br /> <span style={{fontSize:'2rem', color:'#b00505'}}>{departure_pickup_time_hotel}</span></p>
                        </div>
                    )
                }
            </div>
        </Fragment>
    )
}
