import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import { Container , Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import Summary from '../confirmation/summary'
import { getReservationByFolio, putReservation } from '../../api_controllers/reservations_controller';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PaymentStripe from '../booking/payment-stripe';
import { parserEmailData, sendMail } from '../../api_controllers/email_controller';
import Swal from 'sweetalert2';

export default function CheckoutPage() {
    let history = useHistory();
    const { id } = useParams();
    const [data, setData] = useState({})
    const [activeTab, setActiveTab] = useState("1");
    // const [paymentID, setPaymentID] = useState('');

    const getReservation = (id) => {
        getReservationByFolio(id).then((response) => {
            if (response.data.length > 0) {
                let reserve = response.data;
                setData(reserve[0]);
                // setPaymentID(reserve[0].payment_id);
            }
        });
    };
    // const validataIfPayment = (payment_id, id) => {
    //     console.log(payment_id);
    //     if(payment_id !== 'Pendiente'){
    //         history.push({ pathname: `/confirmation/${id}`});
    //         window.location.reload();
    //     }
    // }
    useEffect(() => {
        getReservation(id)
    }, [id]);

    // useEffect(() => {
    //     validataIfPayment(paymentID, id)
    // }, [paymentID, id]);

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    // console.log(data);

    const handlePayNow = (id) => {
        let dataToSend = {
            folio: data.folio,
            payment_id: id,
            trip_type: data.trip_type,
            unit: data.unit,
            pickup_location: data.pickup_location,
            destination_location: data.destination_location,
            total_passengers: data.total_passengers,
            fullname: data.fullname,
            email: data.email,
            cellphone: data.cellphone,
            arrival_datetime: data.arrival_datetime ? data.arrival_datetime : null,
            arrival_airline: data.arrival_airline ? data.arrival_airline : null,
            arrival_flight_number: data.arrival_flight_number ? data.arrival_flight_number : null,
            departure_datetime: data.departure_datetime ? data.departure_datetime : null,
            departure_airline: data.departure_airline ? data.departure_airline : null,
            departure_flight_number: data.departure_flight_number ? data.departure_flight_number : null,
            hotel_departure_time: data.hotel_departure_time ? data.hotel_departure_time : null,
            extra_service: data.extra_service ? data.extra_service : null,
            observations: data.observations ? data.observations : null,
            payment_method: data.payment_method,
            discount_code: data.discount_code,
            discount_percent: data.discount_percent,
            total_payment: data.total_payment,
        };
        console.log(dataToSend);
        putReservation(dataToSend, data.id_reservation) //update reservation
            .then((res) => {
                let email_data = parserEmailData(dataToSend);
                console.log(email_data);
                sendMail(email_data).then((_response) => {
                    let resp = _response;
                    console.log(resp);
                    Swal.fire("", "The reservations was created successfully.", "success").then(() => {
                        history.push({ pathname: `/confirmation/${dataToSend.folio}`, state: {data, email_data} });
                        localStorage.removeItem("desc");
                        window.location.reload();

                    });
                });                
                Swal.fire("", "The reservations was created successfully.", "success").then(() => {
                    history.push({ pathname: `/confirmation/${dataToSend.folio}`, state: {data} });
                    localStorage.removeItem("desc");
                    window.location.reload();

                });
            })
            .catch((e) => {
                console.log("Error:1:", e);
                Swal.fire("Oops!", "Something went wrong when creating reservation... try again later.", "error");
            });
    }; 
   
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Checkout Page - La Isla Tour / Marina Transpotation</title>
                <link rel="canonical" href="https://booking-laislatour.transroute.com.mx/checkout" />
            </Helmet>
            <Container className='checkout-page'>
                <Row className="my-3">
                    <Col xs={12} md={6}>
                        <h1>Checkout Page</h1>                       
                    </Col>
                    <Col xs={12} md={6} style={{display:'flex',alignItems: 'center', justifyContent:'flex-end'}}>
                        <div>
                            <h3 style={{textAlign: 'right', fontWeight:600}}>Total: ${data?.total_payment} USD</h3>
                            <h4 style={{textAlign: 'right', color: '#000000', fontWeight:600}}>Reservation ID: {data?.folio}<br /></h4>
                        </div>
                    </Col>
                </Row>
                <Row className="data-information">
                    <Col xs={12} md={4}>
                        <Summary data={data} title="Checkout Reservation" />
                    </Col>
                    <Col xs={12} md={8}>
                        <h2 style={{color:'#000000'}}>Payment Method</h2>
                         <h3 className="my-3">
                            {/* <FontAwesomeIcon icon="credit-card" className="mr-3" />  */}
                            {/* {lang === "eng" ? "Payment details" : "Método de Pago"} */}
                            </h3>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    <FontAwesomeIcon icon="credit-card" /><br />
                                    {/* {lang === 'eng' ? 'CREDIT CARD' : 'TARJETA DE CRÉDITO'} */}
                                    VISA / MASTER CARD
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    <FontAwesomeIcon icon="credit-card" /><br />
                                    {/* {lang === 'eng' ? 'CREDIT CARD' : 'TARJETA DE CRÉDITO'} */}
                                    REQUEST PAY AMEX CARD
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col xs="12">
                                        <div className={activeTab === 1 ? 'pymnt-itm active' : 'pymnt-itm'}>

                                            <div className="pymnt-cntnt">
                                                <PaymentStripe totalPayment={data?.total_payment} lang={'eng'} handlePayNow={handlePayNow} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col xs="12">
                                        <div className={activeTab === 2 ? 'pymnt-itm active' : 'pymnt-itm'}>
                                            <div className="pymnt-cntnt" style={{minHeight:'100px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <a
                                                    style={{
                                                        backgroundColor:'#25d366',
                                                        padding:'1rem',
                                                        color:'white',
                                                        textDecoration:'none',
                                                        borderRadius:'1rem'
                                                    }}
                                                    target='_blank'
                                                    href={`https://wa.me/6241259657?text=I+Need+to+Pay+with+Amex+Card+Please,+Transportation+for+RCI-Transroute+ID:+${id}&type=phone_number&app_absent=0`}>
                                                        Click for Request Pay with American Express
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                        {/* <Alert color="warning">
                            If you wish to pay with a debit or credit card, please click on the dark gray button and scroll/touch down to view all fields and the Pay Now button, please
                        </Alert>
                        <iframe src={`${configs.URL_PAYMENT_PAYPAL}/?paypal=true&description=${localStorage.getItem('desc')}&idReservation=${id}&rate=${data?.total_payment}&project=isla`} frameBorder="0" style={{minHeight:'750px', overflowY:'auto', width:'100%'}}/> */}
                    </Col>
                   
                </Row>
            </Container>
        </>
    )
}