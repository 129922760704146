import React, { useEffect, useState } from 'react'
import { Container , Row, Col } from 'reactstrap';
// import { getReservationByFolio } from '../../api_controllers/reservations_controller';
import moment from 'moment';

export default function Summary({data, page}) {
    const [isOneWayAirportDestination, setIsOneWayAirportDestination] = useState(false);
    // console.log('form:', data);
    useEffect(() => {
      if(data.destination_location === 'Airport SJD') {
        setIsOneWayAirportDestination(true);
    } else {
        setIsOneWayAirportDestination(false);
    }
    }, [data.destination_location])

    return (
            <Container>
                <Row>
                    <Col xs={12}>
                        <h2 style={{color:'#000000'}}>Traveler information</h2>
                        <h3>{data?.trip_type}</h3>
                    </Col>                    
                    <Col xs={12} md={page === 'confirmation'? 6 : 12}>
                        <h5 style={{marginBottom:'1rem',marginTop:'1.5rem'}}>Customer information</h5>
                        <h6>Full name:<br /> <span>{data?.fullname}</span></h6>
                        <h6>Phone:<br /> <span>{data?.cellphone}</span></h6>
                        <h6>E-mail:<br /> <span>{data?.email}</span></h6>
                        <h5 style={{marginBottom:'1rem',marginTop:'1.5rem'}}>Transport selected</h5>
                        <h6>Vehicle:<br /> <span>{data?.unit}</span></h6>
                        <h6>Trip type:<br /> <span>{data?.trip_type}</span></h6>
                        <h6>Passenger number:<br /> <span>{data?.total_passengers}</span></h6>
                        <h6>Pickup location:<br /> <span>{data?.pickup_location}</span></h6>
                        <h6>Destination:<br /><span>{data?.destination_location}</span></h6>
                    </Col>
                    <Col xs={12} md={page === 'confirmation'? 6 : 12}>
                        
                        {
                            !isOneWayAirportDestination && (<>
                                <h5 style={{marginBottom:'1rem',marginTop:'1.5rem'}}>Pickup Information:</h5>
                                <h6>Pickup Location:<br /> <span>{data?.pickup_location}</span></h6>
                                <h6>Date / Time:<br /> <span>{moment(data?.arrival_datetime).format("MM/DD/YYYY")} - { moment(data?.arrival_datetime).format('h:mm a') }</span></h6>
                                <h6>Arrival Airline:<br /> <span>{data?.arrival_airline}</span></h6>
                                <h6>Arrival Flight Number:<br /> <span>{data?.arrival_flight_number}</span></h6>
                                <hr />
                            </>
                            )
                        }
                        {
                            (data?.trip_type === 'Round Trip') || (isOneWayAirportDestination) ? <>
                            <h5 style={{marginBottom:'1rem',marginTop:'1.5rem'}}>Departure information:</h5>
                            <h6>Departure Location:<br /> <span>{data?.destination_location}</span></h6>
                            <h6>Departure Date / Time:<br /> <span>{moment(data?.departure_datetime).format("MM/DD/YYYY")} - { moment(data?.departure_datetime).format('h:mm a') }</span></h6>
                            <h6>Departure Airline:<br /> <span>{data?.departure_airline}</span></h6>
                            <h6>Departure Flight Number:<br /> <span>{data?.departure_flight_number}</span></h6>
                            <h6 style={{border:'1px solid tomato',padding:'1rem',borderRadius:'8px'}}>
                                Scheduled Hotel Pickup Time:<br /> <span>{data?.hotel_departure_time}</span></h6>
                            </>: null
                        }
                    </Col>
                    {
                        data.extra_service && (
                            <Col xs={12}>
                                <h5>Extra Service:</h5>
                                <h6><span>{data?.extra_service}</span></h6>
                            </Col>
                        )
                    }
                    {
                        data?.observations && (
                            <Col xs={12}>
                                <h5>Observations:</h5>
                                <h6><span>{data?.observations}</span></h6>
                            </Col>
                        )
                    }
                </Row>
            </Container>
    )
}